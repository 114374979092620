import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Spinner, Form } from "react-bootstrap";
import ReactBreadcrumb from "../layout/BreadCrumb";
import ReactPlaceholder from "react-placeholder";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategoryQuery } from "../../features/productsApi";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { addCart, getCart } from "../../features/apiCall";
import axios from "../../utils/axios";
import { Rating } from "react-simple-star-rating";
import {
  reviewFailure,
  reviewStart,
  reviewSuccess,
} from "../../features/reviewSlice";
import { motion } from "framer-motion";
import {
  getProductFailure,
  getProductStart,
  getProductSuccess,
} from "../../features/getProdSlice";
import {
  relatedProdsFailure,
  relatedProdsStart,
  relatedProdsSuccess,
} from "../../features/relatedProdSlice";
import ReactImageMagnify from "react-image-magnify";
import moment from "moment";

import ProductDetailsTabs from "../layout/ProductDetailsTabs";
import RelatedProds from "../layout/RelatedProds";
import AlertDanger from "./../layout/AlertDanger";

const ShopProduct = () => {
  const [productAddded, setProductAddded] = useState(false);
  const navigate = useNavigate();
  const { reviews } = useSelector((state) => state.review);
  const { token } = useSelector((state) => state.auth);
  const { cartItems, isFetching } = useSelector((state) => state.cart);
  const { product, loadingProduct, productErr } = useSelector(
    (state) => state.product
  );
  const dispatch = useDispatch();
  const params = useParams();

  const [count, setCount] = useState(1);
  const [category, setCategory] = useState();
  const [selectedImg, setSelectedImg] = useState("");
  const [date, setDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );

  const [userPin, setUserPin] = useState();

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const getProduct = async () => {
    dispatch(getProductStart());
    try {
      if (!token) {
        const { data } = await axios.get(`/api/product/${params?.id}`);
        setSelectedImg(data.product?.product_images[0]);
        await dispatch(getProductSuccess(data.product));
      } else {
        const { data } = await axios.get(
          `/api/product/all-info/${params?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        setSelectedImg(data.product?.product_images[0]);
        await dispatch(getProductSuccess(data.product));
        
        setUserPin(data?.userZipCode);
      }
    } catch (error) {
      await dispatch(getProductFailure(error?.response?.data?.error?.message));
    }
  };

  const getRecentProducts = async () => {
    dispatch(relatedProdsStart());
    try {
      const { data } = await axios.get(
        `/api/category/${product?.category?._id}/products`
      );
      dispatch(relatedProdsSuccess(data?.products));
    } catch (error) {
      dispatch(relatedProdsFailure(error?.response?.data?.error?.message));
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (token) {
      getCart(dispatch);
    }

    getProduct();
  }, [token]);

  const getReviews = async () => {
    dispatch(reviewStart());

    try {
      const { data } = await axios.get(`/api/review/all/${params?.id}`, {
        headers: { Authorization: token },
      });

      dispatch(reviewSuccess(data?.reviews));
    } catch (error) {
      dispatch(reviewFailure(error?.response?.data?.error?.message));
    }
  };

  const { data: categoryData, isLoading: categoryLoading } =
    useGetCategoryQuery(product?.category?._id);

  useEffect(() => {
    window.scroll(0, 0);
    setCategory(categoryData?.category);

    getReviews();
    getRecentProducts();

    setSelectedImg(product?.product_images[0]);
  }, [categoryData?.category]);

  const handleCount = (change) => {
    if (change === "dec") {
      if (count === 1) {
        setCount(count);
      } else {
        setCount((prev) => prev - 1);
      }
    }

    if (change === "inc") {
      setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (cartItems.length !== 0) {
      if (
        cartItems
          ?.filter((prod) => prod?.product?._id === params.id)
          .map((prod) => prod?.quantity)
          .toString() !== count?.toString()
      ) {
        setProductAddded(false);
      } else {
        setProductAddded(true);
      }
    }
  }, [cartItems, count, params.id]);

  const handleCart = async (product, count, amount, date) => {
    const prodId = product?._id;
    await addCart(dispatch, { prodId, count, date });

    await getCart(dispatch);

    setTimeout(() => {
      navigate("/home/cart");
    }, 1000);
  };

  return (
    <>
      <ReactBreadcrumb path={`Home / Shop-Product`} />
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        {productErr ? (
          <Container style={{ paddingTop: "5rem" }}>
            <AlertDanger
              heading={"Requested product not found!"}
              desc={"This product no longer exists."}
            />
          </Container>
        ) : (
          <Container style={{ paddingTop: "3rem" }}>
            <Row className="product-content">
              <ReactPlaceholder
                type="media"
                color="#F0F0F0"
                showLoadingAnimation
                rows={7}
                ready={!loadingProduct && !categoryLoading}
              >
                <Col md={5} className="px-5">
                  {/* <Zoom {...zoomOutProperties}> */}
                  {/* {product?.product_images?.map((each, index) => ( */}
                  <div
                    key={Math.random()}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    {/* <CardTop path={special} /> */}
                    {/* <CardTop path={selectedImg} /> */}

                    <div className="shop-top-img">
                      {product?.sale && (
                        <div className="top-right">
                          <span>-10</span>
                          <span>New</span>
                        </div>
                      )}
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "image",
                            isFluidWidth: true,
                            src: selectedImg,
                          },
                          largeImage: {
                            src: selectedImg,
                            width: 1000,
                            height: 1000,
                          },
                        }}
                        enlargedImagePosition="over"
                      />
                    </div>
                  </div>
                  {/* ))} */}
                  {/* </Zoom> */}
                  <Row className="m-0 mt-2">
                    {product?.product_images?.length > 0 ? (
                      product?.product_images?.map((i) => (
                        <Col
                          xs={6}
                          sm={3}
                          lg={3}
                          md={5}
                          // xl={2}
                          className="px-0 justify-content-center align-items-center"
                          // style={{ backgroundColor: "#00000026" }}
                          key={i}
                        >
                          <div className="sub-images">
                            {/* <CardImg imgPath={featured1} /> */}
                            <img
                              src={i}
                              alt={product?.name}
                              onClick={() => setSelectedImg(i)}
                            />
                          </div>
                        </Col>
                      ))
                    ) : (
                      <div style={{ padding: "1rem" }}>
                        <p>No image</p>
                      </div>
                    )}
                    {/* <Col className="px-1" style={{ backgroundColor: "#00000026" }}>
                  <CardImg imgPath={product?.product_images[0]} />
                </Col> */}
                  </Row>
                </Col>
                <Col md={7}>
                  <div className="prod-header">
                    <p className="sub-head-1 m-0">
                      Shop<span> / </span> {category?.name}
                    </p>
                    <h2 className="sub-head-2">{product?.name}</h2>

                   
                  </div>
                  <div className="divider"></div>
                  <div className="prod-body">
                    <div className="product-rating">
                      <Rating
                        initialValue={product?.rating}
                        readonly={true}
                        allowHover={false}
                        allowFraction={true}
                      />
                      <p style={{ marginLeft: "1rem" }}>
                        {2} ratings
                      </p>
                    </div>

                    <div className="price">
                      <p style={{ color: "#FF3232" }}>
                        Rs {product?.amount?.toFixed(2)}
                      </p>
                    </div>

                    

                    

                    {/* <div className="prod-stock-info">
                      {product?.stock ? (
                        <p
                          style={{
                            color: "green",
                          }}
                        >
                          In stock
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          Out of stock
                        </p>
                      )}
                    </div> */}


                  </div>
                  <div className="prod-footer mt-2">
                    <ReactPlaceholder
                      type="text"
                      color="#F0F0F0"
                      showLoadingAnimation
                      rows={1}
                      ready={!categoryLoading && !loadingProduct}
                    >
                      <p>Category: {category?.name}</p>
                    </ReactPlaceholder>
                  </div>
                </Col>
              </ReactPlaceholder>
            </Row>

            <ProductDetailsTabs
              loadingProduct={loadingProduct}
              product={product}
            />

            <hr />

            <RelatedProds
              categoryLoading={categoryLoading}
              categoryId={product?.category?._id}
            />
          </Container>
        )}
      </motion.div>

      <ToastContainer />
    </>
  );
};

export default ShopProduct;
