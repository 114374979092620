import React, { useEffect, useReducer } from "react";
import axios from "../../utils/axios";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useSelector } from "react-redux";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        companyDetails: action.payload.staticContent,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const OurSatisfiedCustomers = () => {
  const [{ loading, error, companyDetails }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const { token } = useSelector((state) => state.auth);

  const responsiveSettings = [
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const { data } = await axios.get(`/api/static/get-static`);
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (error) {
        dispatch({
          type: "FETCH_FAIL",
          payload: error,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <div className="sec-2">
      <div className="sec-1-heading">
        <h1 className="h-heading">OUR SATISFIED CUSTOMERS</h1>
      </div>
      <ReactPlaceholder
        type="text"
        color="#F0F0F0"
        showLoadingAnimation
        rows={7}
        ready={!loading}
      >
        <div style={{ margin: "0.5rem" }}>
          <Slide
            indicators={true}
            autoplay={false}
            infinite={false}
            responsive={responsiveSettings}
          >
            {companyDetails?.customerReview?.map((review) => (
              <div style={{ margin: "1rem" }} key={review?._id}>
                <div>
                  <div
                    className="our-satisfied-customer-root"
                    style={{ height: "30rem" }}
                  >
                    <div className="our-satisfied-customer-name">
                      <p>{review?.customerName}</p>
                    </div>

                    <div className="our-satisfied-customer-review">
                      <p className="our-satisfied-customer-lefticon">
                        <FaQuoteLeft />
                      </p>
                      <p className="our-satisfied-customer-review-txt">
                        {review?.customerReview}
                      </p>
                      <p className="our-satisfied-customer-righticon">
                        <FaQuoteRight />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slide>
        </div>
      </ReactPlaceholder>
    </div>
  );
};

export default OurSatisfiedCustomers;
