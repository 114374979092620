import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
// import app_logo_black from "./app_logo_black.png";
import app_logo_white from "./logo.jpg";

const Footer = () => {
  const path = useLocation();

  return (
    <>
      {path.pathname === "/" ? (
        <footer
          className=""
          style={{
            width: "100%",
            position: "static",
            bottom: "0px",
            backgroundColor: "#000",
          }}
        >
          <div className="footer-div-home">
            <img alt="" src={app_logo_white} width="90" />
            {/* <h1>Logo.</h1> */}

            <div>
              <p>
                So by now, you’re probably wondering- what’s the difference
                between an online and a physical stores? Well, let us take you
                on a quick tour around the world of academic projects and It services
              </p>

              {/* <div className="footer-divider"></div> */}
              <hr className="footer-divider-home" />
              <div className="rights-footer-home">
                <p>@ 2023 Projectkart. all rights reserved</p>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer
          className=""
          style={{
            width: "100%",
            position: "static",
            bottom: "0px",
            backgroundColor: "#EFEFEF",
            marginTop: "6rem",
          }}
        >
          <div className="footer-div">
            <>
              <img
                alt=""
                src={""}
                width="90"
              // height="30"
              // className="d-inline-block align-top app-logo"
              />
            </>
            <Row>
              <Col md={4} lg={"auto"}>
                <div className="footer-icons">
                  <div className="icons">
                    <a href="https://instagram.com/projectkart_?igshid=MzRlODBiNWFlZA==">
                      <AiOutlineInstagram />
                    </a>

                   
                    <a href="https://www.linkedin.com/in/project-kart-157133291">
                      <AiFillLinkedin  />
                    </a>
                    
                  </div>

                </div>
              </Col>
              {/* <Col xs={1} className="footer-info"> */}
              <Col md={2} lg={2}>
                
              </Col>
              <Col md={2} lg={2}>
                <div>
                  <p className="heading-txt">Help</p>

                  <div className="options-txt">
                    <Link
                      to="/home/about-us"
                      style={{ textDecoration: "none" }}
                    >
                      <p>About Us</p>
                    </Link>
                    <Link to="/home/contact-us">
                      <p>Contact Us</p>
                    </Link>
                    
                  </div>
                </div>
              </Col>
              <Col md={2} lg={2}>
                <div>
                  <p className="heading-txt">Shop</p>

                  <div className="options-txt">
                    <Link to="shop/6514e813d5ec41e95e97c874">
                      <p>All products</p>
                    </Link>

                    {/* <Link to="home/my-account#refer">
                      <p>Refer a friend</p>
                    </Link> */}

                    {/* <p>Bedroom</p>
                    <p>Dinning Room</p> */}
                  </div>
                </div>
              </Col>
          
              {/* </Col> */}
            </Row>
            <div>
              {/* <div className="footer-divider"></div> */}
              <Row>
                <div className="rights-footer">
                  <center>
                    <p>@ 2023 ProjectKart. all rights reserved</p>
                  </center>
                </div>
              </Row>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
