import { Button, Carousel, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import one from "./one.jpg";
import two from "./two.jpg";
import three from "./three.jpg";
import four from "./four.jpg";

import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

const CarouselItemComponent = ({ path }) => {
  return (
    <>
      <img className="d-block m-auto" height={500} src={one} alt={path} />
      <Carousel.Caption style={{ position: "relative", right: 0, left: 0 }}>
        {/* <img src="/images/StartShopping2.png" alt="" /> */}
        caption
      </Carousel.Caption>
    </>
  );
};
export default function CustomCarousel({ promotions }) {
  // return (

  //   <Carousel>
  //     <Carousel.Item interval={1000} style={{ backgroundColor: "#abf3ea" }}>
  //       <CarouselItemComponent path={"banneroverlayY1.png"} />
  //     </Carousel.Item>

  //     <Carousel.Item interval={1000} style={{ backgroundColor: "#abf3ea" }}>
  //       <CarouselItemComponent path={"banner-overlay123.png"} />
  //     </Carousel.Item>

  //     <Carousel.Item
  //       interval={2000}
  //       style={{
  //         backgroundColor: "#abf3ea",
  //         backgroundImage: "url(/images/BannerImagedancer.jpg)",
  //         backgroundRepeat: "no-repeat",
  //         backgroundSize: "100% 100%",
  //       }}
  //     >
  //       <CarouselItemComponent path={"banner-overlay3D.png"} />
  //     </Carousel.Item>
  //   </Carousel>
  // );
  const navigate = useNavigate();
  return (
    <>
      <Carousel style={{ cursor: "pointer", backgroundColor: "#000" }}>
        {/* {promotions?.map((promotion) => (
          <Carousel.Item
            interval={1000}
            key={promotion?._id}
            className="carousel-item"
          >
            <img
              style={{ color: "white" }}
              className="d-block m-auto img-fluid carousel-img"
              // src="/images/homeCorouselImg.jpeg"
              src={promotion?.product?.product_images[0]}
              alt={promotion?.product?.name}
            />
           

            <Carousel.Caption>
              <div
                style={{
                  backgroundColor: "rgba(1, 1, 1, 0.5)",
                  borderRadius: "1rem",
                }}
                className="carousel-caption"
              >
                <div className="carousel-div">
                  <span className="carousel-product-name">
                    {promotion?.product?.name}
                  </span>
                  <span className="carousel-product-price">
                    ${promotion?.product?.amount}
                  </span>
                </div>
                <span className="carousel-product-updated">
                  Available at only ${promotion?.updated_price}
                </span>

                <Button
                  variant="outline-light"
                  onClick={() => navigate(`/home/${promotion?.product?._id}`)}
                >
                  Buy Now
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))} */}

        {/* <ReactPlaceholder
          type="media"
          color="#fff"
          showLoadingAnimation
          rows={5}
          ready={!promosLoading}
        > */}
        {/* {promotions?.map((promo) => (
          <Carousel.Item key={promo?._id}>
            <img
              className="d-block w-100"
              src={promo?.promo_image}
              alt="First slide"
            />
          </Carousel.Item>
        ))} */}
        {/* </ReactPlaceholder> */}

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={three}
            style={{ height: "500px", objectFit: "fill" }}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ height: "500px", objectFit: "fill" }}
            src={four}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ height: "500px", objectFit: "fill" }}
            src={one}
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
}