import React, { useEffect, useState } from "react";
import { NavDropdown, Row, Col } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
// import {
//   useGetAllCategoriesQuery,
//   useGetAllSubCategoriesQuery,
// } from "../../features/productsApi";
import { useNavigate } from "react-router-dom";
import { useGetAllCategoriesQuery } from "../../features/productsApi";

const DropdownComp = ({ width }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const { data: categoryData, isLoading: categoryLoading } =
    useGetAllCategoriesQuery();

  //   const { data: subCategoryData, isLoading: subCategoryLoading } =
  //     useGetAllSubCategoriesQuery();

  useEffect(() => {
    if (categoryData) {
      setCategories(categoryData?.categories);
    }

    // console.log(categoryData?.categories);
  }, [categoryData]);

  return (
    <Row style={{ borderRadius: "1rem" }}>
      <ReactPlaceholder
        type="text"
        color="#F0F0F0"
        showLoadingAnimation
        rows={3}
        ready={!categoryLoading}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          className={`${width ? "small-dropdown" : "dropdown-div"} `}
        >
          {categories?.map((catitem) => (
            <>
              <p key={catitem?._id}>
                <>
                  <NavDropdown.Item
                    key={Math.random()}
                    onClick={() => navigate(`/shop/${catitem?._id}`)}
                    className="dropdown-box"
                  >
                    <span>{catitem?.name}</span>
                  </NavDropdown.Item>
                </>
                {/* <NavDropdown.Divider /> */}

                {/* {subCategories
                ?.filter((item) => item?.category?._id === catitem?._id)
                .map((subitem) => (
                  <NavDropdown.Item
                    className="dropdown-list-item"
                    key={subitem?._id}
                    onClick={() => navigate(`/shop/${subitem?._id}?sub=true`)}
                  >
                    <ul>
                      <li>{subitem?.name}</li>
                    </ul>
                  </NavDropdown.Item>
                ))} */}
              </p>
            </>
          ))}
          {/* <Col>
            <div
              className="speacials-container"
              style={{ borderRadius: "0.2rem" }}
            >
              <div className="speacials-text">Specials</div>
            </div>
          </Col> */}
        </div>
      </ReactPlaceholder>
    </Row>
  );
};

export default DropdownComp;
