import React from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AlertDanger = ({ heading, desc, onHome }) => {
  const navigate = useNavigate();
  return (
    <Alert
      variant="danger"
      style={{ width: "50%", margin: "auto" }}
      className="no-prod-msg-box-alert"
    >
      <Alert.Heading className="no-prod-msg-box">
        <span>{heading}</span>
      </Alert.Heading>

      <hr />
      <p className="mb-5">{desc}</p>
      <hr />

      {!onHome && (
        <Alert.Link
          as="button"
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
          }}
          onClick={() => navigate("/")}
          className="no-prod-link"
        >
          <span>Go to home</span>
        </Alert.Link>
      )}
    </Alert>
  );
};

export default AlertDanger;
