import React, { useEffect, useReducer, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Alert,
  Card,
} from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { useNavigate, useParams } from "react-router-dom";
import ReactBreadcrumb from "../layout/BreadCrumb";
import {
  useGetAllCategoriesQuery,
  useGetShopDetailsQuery,
} from "../../features/productsApi";
import axios from "../../utils/axios";
import { motion } from "framer-motion";
import AllCategories from "../layout/AllCategories";
import AlertDanger from "../layout/AlertDanger";
import ProductList from "../layout/ProductList";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };

    case "FETCH_SUCCESS":
      return {
        ...state,
        products: action.payload,
        productsCount: action.payload?.length,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const Shop = () => {
  const [{ loading, products, productsCount, error }, dispatch] = useReducer(
    reducer,
    { loading: true, error: "" }
  );

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [width, setWidth] = useState();
  const params = useParams();
  const [showList, setShowList] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");

  //991
  const getSearchedProducts = async () => {
    if (searchProduct.length > 0) {
      dispatch({ type: "FETCH_REQUEST" });

      try {
        const { data } = await axios.get(
          `/api/product/all/?keyword=${searchProduct}`
        );

        dispatch({ type: "FETCH_SUCCESS", payload: data?.products });
      } catch (error) {
        console.log(error);

        dispatch({
          type: "FETCH_FAIL",
          payload: error?.response?.data?.error?.message,
        });
      }
    }
  };

  const getCategory = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const { data } = await axios.get(`/api/category/${params?.id}/products`);

      dispatch({ type: "FETCH_SUCCESS", payload: data?.products });
    } catch (error) {
      console.log(error?.response?.data?.error?.message);

      dispatch({
        type: "FETCH_FAIL",
        payload: error?.response?.data?.error?.message,
      });
    }
  };

  const { data: categoryData, isLoading: categoryLoading } =
    useGetAllCategoriesQuery();

  useEffect(() => {
    window.scrollTo(0, 0);

    getCategory();

    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    setCategories(categoryData?.categories);
  }, [params?.id, categoryData]);

  return (
    <>
      <ReactBreadcrumb path={`Home / Shop`} />

      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Container>
          <div className="shop-sec-1">
            <div className="filter-results">
              <div className="filters-txt">
                <p>Product Catalog</p>
              </div>

              <ReactPlaceholder
                type="text"
                color="#efefef"
                showLoadingAnimation
                rows={1}
                style={{ width: "60%" }}
                ready={!categoryLoading && !loading}
              >
                <>
                  <p className="m-0 result-length">
                    {productsCount > 0 &&
                      `Showing 1-${productsCount} of ${productsCount} results`}
                  </p>
                </>
              </ReactPlaceholder>
            </div>
            <div className="search-form">
              <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                <InputGroup>
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="search-box"
                    aria-label="Search"
                    onChange={(e) => setSearchProduct(e.target.value)}
                    value={searchProduct}
                  />
                  <InputGroup.Text
                    onClick={() => {
                      getSearchedProducts();
                    }}
                    className="search-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </InputGroup.Text>
                </InputGroup>
              </Form>
            </div>
          </div>
          <div className="divider"></div>
          <div className="categories-txt">
            <p>
              {width > 991 ? (
                "Categories"
              ) : (
                <div className="toggle-category">
                  <p>Categories</p>
                  {width < 991 && (
                    <p
                      className="drop-arrow"
                      onClick={() => setShowList(!showList)}
                    >
                      {showList ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="category-down"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="category-down"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      )}
                    </p>
                  )}
                </div>
              )}
            </p>
          </div>
          <Row>
            {width < 991 ? (
              showList && (
                <AllCategories
                  categories={categories}
                  categoryLoading={categoryLoading}
                  setSearchProduct={setSearchProduct}
                  setShowList={setShowList}
                  showList={showList}
                />
              )
            ) : (
              <AllCategories
                categories={categories}
                categoryLoading={categoryLoading}
                setSearchProduct={setSearchProduct}
                setShowList={setShowList}
                showList={showList}
              />
            )}
            <Col>
              <Row>
                <ReactPlaceholder
                  type="media"
                  color="#F0F0F0"
                  showLoadingAnimation
                  rows={7}
                  delay={200}
                  ready={!loading && !categoryLoading}
                >
                  {error ? (
                    <AlertDanger
                      heading={"Requested product not found!"}
                      desc={"This product no longer exists."}
                    />
                  ) : (
                    <>
                      {products?.length > 0
                        ? products?.map((item) => (
                            <ProductList item={item} key={Math.random()} />
                          ))
                        : (searchProduct?.length > 0 ||
                            products?.length === 0) && (
                            <AlertDanger
                              heading={"Oh snap! Products not found."}
                              desc={"Products will be added soon!"}
                            />
                          )}
                    </>
                  )}
                </ReactPlaceholder>
              </Row>
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default Shop;
