const chapters = [
  {
    "index": 1,
    "chapterName": "Definities",
    "pageNumber": 3
  },
  {
    "index": 2,
    "chapterName": "Toepasselijkheid",
    "pageNumber": 4
  },
  {
    "index": 3,
    "chapterName": "Aanbod",
    "pageNumber": 4
  },
  {
    "index": 4,
    "chapterName": "Totstandkoming van de Overeenkomst",
    "pageNumber": 5
  },
  {
    "index": 5,
    "chapterName": "Wijzigingen",
    "pageNumber": 5
  },
  {
    "index": 6,
    "chapterName": "Inschakeling derden",
    "pageNumber": 6
  },
  {
    "index": 7,
    "chapterName": "Prijzen",
    "pageNumber": 6
  },
  {
    "index": 8,
    "chapterName": "Facturering en betaling",
    "pageNumber": 6
  },
  {
    "index": 9,
    "chapterName": "Verplichtingen Klant",
    "pageNumber": 7
  },
  {
    "index": 10,
    "chapterName": "Installatie",
    "pageNumber": 8
  },
  {
    "index": 11,
    "chapterName": "Levering en risico",
    "pageNumber": 9
  },
  {
    "index": 12,
    "chapterName": "Garantie",
    "pageNumber": 10
  },
  {
    "index": 13,
    "chapterName": "Melding",
    "pageNumber": 11
  },
  {
    "index": 14,
    "chapterName": "Eigendomsvoorbehoud",
    "pageNumber": 11
  },
  {
    "index": 15,
    "chapterName": "Privacy",
    "pageNumber": 12
  },
  {
    "index": 16,
    "chapterName": "Opschorting en ontbinding",
    "pageNumber": 12
  },
  {
    "index": 17,
    "chapterName": "Overmacht",
    "pageNumber": 12
  },
  {
    "index": 18,
    "chapterName": "Aansprakelijkheid",
    "pageNumber": 13
  },
  {
    "index": 19,
    "chapterName": "Geheimhouding",
    "pageNumber": 14
  },
  {
    "index": 20,
    "chapterName": "Intellectuele eigendomsrechten",
    "pageNumber": 14
  },
  {
    "index": 21,
    "chapterName": "Boete",
    "pageNumber": 15
  },
  {
    "index": 22,
    "chapterName": "Overige bepalingen",
    "pageNumber": 15
  }
];
export default chapters;
