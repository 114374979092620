import CardImg from "./CardImg";

export default function CardTop({ path }) {
  return (
    <div className="shop-top-img">
      <div className="top-right">
        <span>-10</span>
        <span>New</span>
      </div>
      <CardImg imgPath={path} />
    </div>
  );
}
