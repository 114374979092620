import React from 'react';
import { Container, Table,Accordion } from 'react-bootstrap';
import chapters from "./chapters";
const TermsAndConditions = () => {
    return (
        <Container
            style={{
                marginTop: "2rem",
            }}
        >
            <h1>
                Algemene voorwaarden
            </h1>
            Zakelijk<br />
            <br />
            Heb je vragen over deze algemene voorwaarden of diensten van E Boss? Neem dan contact met ons op via info@e-boss.nl
            <br /><br />
            Inhoudsopgave:

            <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Hoofdstukken</th>
                        <th>Paginanummer</th>
                    </tr>
                </thead>
                <tbody>
                    {chapters.map((chapter) => (
                        <tr>
                            <td>{chapter.index}</td>
                            <td>{chapter.chapterName}</td>
                            <td>{chapter.pageNumber}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <h1>
                Algemene voorwaarden zakelijk
            </h1>
            <p>
                Deze werkzaamheden worden uitgevoerd op basis van de voorwaarden zoals hieronder omschreven.
            </p>
            <br /><br />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>1.	Definities</Accordion.Header>
                    <Accordion.Body>
                        In deze Algemene voorwaarden wordt verstaan onder:
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Algemene voorwaarden </td>
                                    <td>0nderhavige Algemene voorwaarden van E Boss.</td>
                                </tr>
                                <tr>
                                    <td>Diensten</td>
                                    <td>Al hetgeen E Boss ten behoeve van de Klant, binnen het kader van de Overeenkomst maakt, doet en/of onderneemt.</td>
                                </tr>
                                <tr>
                                    <td>E Boss</td>
                                    <td>E Boss is een handelsmerk van Keystone Financial Services B.V. gevestigd te Vught en staat ingeschreven bij de Kamer van Koophandel onder nummer 70534349.</td>
                                </tr>
                                <tr>
                                    <td>Overeenkomst</td>
                                    <td>Al hetgeen tussen E Boss en de Klant is overeengekomen, inclusief eventuele wijzigingen en aanvullingen.</td>
                                </tr>
                                <tr>
                                    <td>Partij(en) </td>
                                    <td>E Boss en/of de Klant</td>
                                </tr>
                                <tr>
                                    <td>Product(en)</td>
                                    <td>zonne-installatiesystemen en/of alle zaken, hoe ook genaamd  </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>2.	Toepasselijkheid</Accordion.Header>
                    <Accordion.Body>

                        <p>
                            2.1 Op alle rechtsbetrekkingen met E Boss zijn uitsluitend deze Algemene voorwaarden van toepassing. Voor het geval dat naast deze Algemene voorwaarden specifieke product- of dienstenvoorwaarden van toepassing zijn, zijn die voorwaarden eveneens van toepassing.
                        </p> <br /><br />
                        <p>
                            2.2 Door de Klant gehanteerde algemene voorwaarden en andere (van de Algemene voorwaarden afwijkende) bedingen worden door E Boss uitdrukkelijk van de hand gewezen.
                        </p> <br /><br />
                        <p>
                            2.3 De Klant met wie eenmaal op basis van deze Algemene voorwaarden werd gecontracteerd, stemt in met de toepasselijkheid daarvan op toekomstige en/of vervolgopdrachten met E Boss.
                        </p> <br /><br />
                        <p>
                            2.4 De Algemene voorwaarden strekken mede ten behoeve van derden die door E Boss worden ingeschakeld.
                        </p> <br /><br />

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>3.	Aanbod</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            3.1	Alle aanbiedingen van E Boss zijn te allen tijde vrijblijvend en hebben een beperkte geldigheidsduur van 14 dagen, tenzij anders overeengekomen.
                        </p> <br /><br />
                        <p>
                            3.2	Alle verstrekte informatie door E Boss (waaronder opbrengsten, terugverdientijden, rendement en/of andere aanduidingen van de Producten en/of Diensten) zijn vrijblijvend en betreffen slechts een algemene weergave. Aan deze gegevens kunnen geen rechten worden ontleend.
                        </p> <br /><br />
                        <p>
                            3.3	Levertijden/termijnen in aanbiedingen of offertes zijn indicatief en geven de Klant bij overschrijding daarvan geen recht op ontbinding en/of schadevergoeding.
                        </p> <br /><br />
                        <p>
                            3.4	Kennelijke vergissingen of kennelijke fouten in het aanbod binden E Boss niet.
                        </p> <br /><br />

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>4.	Totstandkoming van de Overeenkomst</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            4.1	De Overeenkomst komt tot stand indien de Klant het aanbod van E Boss schriftelijk of elektronisch heeft aanvaard.
                        </p> <br /><br />
                        <p>
                            4.2	De Overeenkomst komt alleen tot stand indien de dakconstructie deugdelijk, zonder onvolkomenheden en/of gebreken geschikt is om het Product te installeren en aan te sluiten.
                        </p> <br /><br />
                        <p>
                            4.3	De Overeenkomst komt niet tot stand indien de Klant geen eigenaar van het betreffende gebouw blijkt te zijn, tenzij een akkoordverklaring van de eigenaar van het gebouw kenbaar wordt gemaakt.
                        </p> <br /><br />
                        <p>
                            4.4	Van deze Algemene voorwaarden en het aanbod van E Boss afwijkende en/of aanvullende bepalingen zijn slechts bindend indien deze door een vertegenwoordigingsbevoegde medewerker van E Boss schriftelijk of elektronisch is aanvaard.
                        </p> <br /><br />
                        <p>
                            4.5	Een beroep op het herroepingsrecht is voor de Klant uitgesloten.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>5.	Wijzigingen</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            5.1	E Boss heeft te allen tijde het recht Overeenkomsten te weigeren of aan de levering en/of uitvoering van de Overeenkomst nadere voorwaarden te verbinden.
                        </p> <br /><br />
                        <p>
                            5.2	Indien nodig behoudt E Boss zich het recht voor om een vergelijkbaar of beter Product aan de Klant te leveren.
                        </p> <br /><br />
                        <p>
                            5.3	Wijzigingen in de Overeenkomst door de Klant dienen tijdig en schriftelijk aan E Boss te worden meegedeeld. De wijzigingen in de Overeenkomst zijn van kracht door en vanaf de schriftelijke aanvaarding ervan door E Boss. Indien de wijzigingen hogere kosten veroorzaken worden deze de Klant extra in rekening gebracht.
                        </p> <br /><br />
                        <p>
                            5.4	Indien wijzigingen in de Overeenkomst door de Klant geheel of gedeeltelijke annulering van de Overeenkomst betekent, is E Boss gerechtigd gemaakte kosten (met een minimum 20% van de overeengekomen prijs), geleden verlies en/of gederfde winst bij de Klant in rekening te brengen.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>6.	Inschakeling derden</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            6.1	Indien zulks naar het oordeel van E Boss redelijkerwijs noodzakelijk is,
                            is E Boss gerechtigd om derden in te schakelen. Zo mogelijk en/of zo nodig zal E Boss de Klant hierover informeren.

                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>7.	Prijzen</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            7.1	Alle prijzen zijn exclusief (omzet)belastingen en heffingen. Tenzij anders overeengekomen komen voor rekening van de Klant de overige kosten die niet in het aanbod zijn genoemd, zoals kosten van verpakking, vervoerskosten, meerwerk en alle overige kosten.
                        </p> <br /><br />
                        <p>
                            7.2	In geval van stijging van kostprijsbepalende factoren na de totstandkoming van de Overeenkomst heeft E Boss het recht de overeengekomen prijs te verhogen. Het voorgaande geeft de Klant geen recht om de overeenkomst geheel of gedeeltelijk te ontbinden.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>8.	Facturering en betaling</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            8.1	De Klant dient binnen vijf (5) dagen na levering overeenkomstig artikel 11, de factuur via overmaking te voldoen, tenzij anders
                            overeengekomen. Indien een voorschot is overeengekomen dient deze binnen vijf (5) dagen na totstandkoming van de Overeenkomst voldaan te zijn, tenzij anders overeengekomen.
                        </p> <br /><br />
                        <p>
                            8.2	De Klant is van rechtswege in verzuim indien niet binnen de overeengekomen termijn is betaald. Tevens zijn alle vorderingen die E Boss op de Klant heeft onmiddellijk opeisbaar, zonder dat een nadere ingebrekestelling is vereist, alsmede is E Boss bevoegd de (verdere) nakoming van alle Overeenkomsten met de Klant op te schorten.
                        </p> <br /><br />
                        <p>
                            8.3	Indien de Klant een machtiging tot automatische incasso aan E Boss heeft verstrekt, is het E Boss toegestaan vanaf de datum van de factuur het verschuldigde bedrag van het bij E Boss bekende rekeningnummer van de Klant af te schrijven. Mocht de verschuldigde vergoeding niet kunnen worden geïncasseerd en/of worden gestorneerd, dan geldt dat de Klant in verzuim is vanaf het tijdstip waarop de verschuldigde vergoeding niet kan worden geïncasseerd dan wel wordt gestorneerd.
                        </p> <br /><br />
                        <p>
                            8.4	Bij een niet tijdige betaling door de Klant en/of indien een automatische incasso wordt gestorneerd, is de Klant over het factuurbedrag vanaf
                            de vervaldag van de factuur tot het moment van volledige voldoening wettelijke handelsrente verschuldigd. Daarnaast is de Klant aansprakelijk voor alle gerechtelijke en buitengerechtelijke kosten verband houdende met de inning van enige vordering. De buitengerechtelijke kosten bedragen in ieder geval 15% van het/de factuurbedrag(en), met een minimum van € 500,00 en zijn zonder nadere aanmaning verschuldigd.
                        </p> <br /><br />
                        <p>
                            8.5	Zonder schriftelijke toestemming van E Boss is het de Klant niet toegestaan de betalingsverplichting(en) jegens E Boss op te schorten en/of te verrekenen met van de Klant op E Boss. Klachten, een beroep op garantie en/of andere aanspraken schorten de betalingsverplichtingen eveneens niet op.
                        </p><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>9.	Verplichtingen Klant</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            9.1	De Klant is verplicht alle gegevens en bescheiden, die E Boss meent nodig te hebben voor het correct uitvoeren van de Overeenkomst, tijdig en in gewenste vorm en op de gewenste wijze aan E Boss ter beschikking te stellen.
                        </p> <br /><br />
                        <p>
                            9.2	Onjuistheden in de opgegeven technische informatie en/of opgedragen werkzaamheden komen voor rekening en risico van de Klant.
                        </p> <br /><br />
                        <p>
                            9.3	De Klant zorgt voor eigen rekening en risico dat de aangewezen plaats van installatie geschikt is voor montage en installatie. De Klant is
                            eveneens verantwoordelijk voor het deugdelijk laten functioneren van de noodzakelijke energievoorziening. De Klant draagt de verantwoordelijkheid en meerkosten voor het geschikt maken van het gebouw voor installatie.
                        </p><br /><br />
                        <p>
                            9.4	Voor de installatie van het Product draagt de Klant zorg voor de toegang van het de installateur en/of materialen tot de locatie waar het systeem wordt geplaatst. De Klant neemt alle passende maatregelen om schade aan personen en/of materialen te voorkomen.
                        </p><br /><br />
                        <p>
                            9.5	De verantwoordelijkheid voor de werking van het Product ligt bij de Klant. Storingen en defecten dienen onmiddellijk schriftelijk aan E Boss te worden gemeld.
                        </p><br /><br />
                        <p>
                            9.6	De Klant is verplicht om het Product overeenkomstig de bestemming te gebruiken.
                        </p><br /><br />
                        <p>
                            9.7	De Klant is verantwoordelijk voor de noodzakelijke vergunningen, goedkeuringen en/of akkoordverklaringen.
                        </p><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>10.	Installatie</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            10.1	Mocht blijken dat de dak- en gevelconstructie tijdens de montage of achteraf onzichtbare onvolkomenheden en/of gebreken vertonen, kan E Boss hiervoor niet aansprakelijk worden gesteld.
                        </p> <br /><br />
                        <p>
                            10.2	E Boss heeft het recht de installatie uit te stellen indien op de dag van installatie (onvoorziene) omstandigheden het niet mogelijk, dan wel onveilig maken om de werkzaamheden uit te voeren. In dat geval heeft de Klant geen recht de Overeenkomst te ontbinden.
                        </p><br /><br />
                        <p>
                            10.3	E Boss behoudt zich het recht voor om de installatiewerkzaamheden niet uit te voeren, dan wel af te breken of de overeenkomst kosteloos te ontbinden, indien na inschatting van E Boss:
                        </p><br />
                        <ul>
                            <li>wordt geconstateerd dat de veiligheid van de medewerkers in het geding is; </li>
                            <li>de locatie niet afdoende bereikbaar/beschikbaar is;</li>
                            <li>c	de dakpannen en/of dakconstructie niet geschikt is;</li>
                            <li>d	asbest en/of andere schadelijke stoffen worden aangetroffen; </li>
                            <li>een gevaarlijke situatie in de meterkast wordt aangetroffen;</li>
                            <li>f	de locatie - naar mening van E Boss - om wat voor reden ook niet geschikt blijkt te zijn;</li>
                            <li>g	blijkt dat de installatie niet volledig opgeleverd kan worden door toedoen van derden en/of de Klant.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>11.	Levering en risico</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            11.1	Door E Boss opgegeven levertermijnen gelden steeds bij benadering en gelden voor E Boss nimmer als fatale termijnen. E Boss is niet aansprakelijk voor eventueel door de Klant geleden schade als gevolg van het niet monteren en installeren binnen een aangegeven termijn.
                        </p> <br /><br />
                        <p>
                            11.2	Ingeval van overschrijding door E Boss van enige termijn is E Boss eerst na een schriftelijke ingebrekestelling met een redelijk gesteld termijn van tenminste veertien (14) werkdagen in verzuim.
                        </p> <br /><br />
                        <p>
                            11.3	Als plaats van levering geldt het adres dat de Klant aan E Boss kenbaar heeft gemaakt.
                        </p><br /><br />
                        <p>
                            11.4	Het Product is geleverd na voltooiing van de installatiewerkzaamheden, dan wel indien de installatie niet binnen een werkdag uitgevoerd kan worden geldt het Product als geleverd indien opslag van de Producten bij de Klant noodzakelijk is. Het Product geldt eveneens als geleverd overeenkomstig het vorige lid, indien - naar oordeel van E Boss - slechts kleine afrondende werkzaamheden resteren (waaronder aansluiting op het wifi netwerk van de Klant, aansluiting op de meterkast, installeren van applicaties en herstellen van eventuele schades).
                        </p><br /><br />
                        <p>
                            11.5	De zaken zijn voor rekening en risico van de Klant na levering, zelfs indien de eigendom nog niet op de Klant is overgedragen. Bij de levering ondertekenen de Klant en de monteur het opleverformulier.
                        </p><br /><br />
                        <p>
                            11.6	Indien de Klant de door E Boss ter aflevering aangeboden Producten niet afneemt, komen alle kosten en schades die hieruit voortvloeien (zoals vervoers-, opslag- en bewaarkosten) voor rekening van de Klant.
                        </p> <br /><br />
                        <p>
                            11.7	De Klant stemt in met het achterlaten van de verpakkingen van het Product.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>12.	Garantie</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            12.1  Bij een juist en zorgvuldig gebruik van de geleverde Producten gelden de in de Overeenkomst overeengekomen garanties. De garanties bestaan uit:
                        </p> <br /><br />
                        <ul>
                            <li>
                                Productgarantie: ook wel fabrieksgarantie genoemd zoals de leverancier van het Product heeft aangegeven. Deze garantie blijft beperkt tot het herstellen of het leveren van een soortgelijk Product, tenzij anders is vermeld in de Overeenkomst. De productgarantie vervalt indien de producent van het
                                Product garanties - al dan niet door onvoorziene omstandigheden - niet meer kan bieden. De kosten voor het vervangen van het Product vallen niet onder de productgarantie.
                            </li>
                            <li>
                                Installatiegarantie: garantie op werkzaamheden die te maken hebben met de complete installatie en oplevering van het Product.</li>
                        </ul>
                        <p>
                            12.2   Garanties strekken zich niet uit tot defecten veroorzaakt door externe factoren, zoals maar niet beperkt tot brand, kortsluiting, waterschade, gebreken aan
                            de meterkast, blikseminslag, vandalisme, diefstal, schade door dieren en/ of mensen, extreme weersomstandigheden en contact met chemische substanties.
                        </p> <br /><br />
                        <p>
                            12.3    Garanties zijn uitgesloten voor:
                        </p> <br />
                        <ul>
                            <li>schade ontstaan door achterstallig onderhoud;</li>
                            <li>schade die niet terug te voeren is op werkzaamheden uitgevoerd door een medewerker van E Boss;</li>
                            <li>onderlinge kleurverschillen en/of verkleuringen van de Producten; </li>
                            <li>schade ontstaan doordat het dak de extra ballast van de Producten niet kan dragen;</li>
                            <li>gebreken aan Producten die door derden zijn geleverd; </li>
                            <li>vernielingen of aanpassingen aan het systeem door de Klant.</li>
                        </ul>
                        <p>
                            12.4   Een geslaagd beroep op garantie(s) houdt uitsluitend in dat E Boss naar haar keuze de betreffende Producten/Diensten zal herstellen, her leveren,
                            vervangen, opnieuw uitvoeren, dan wel de door de Klant betaalde prijs (deels) restitueert.
                        </p><br /><br />
                        <p>
                            12.5  Ten aanzien van door E Boss van derden betrokken Producten en/of Diensten is E Boss slechts aan een eventueel overeengekomen garantie gebonden, indien en voor zover zij op haar beurt van de betreffende derde ter zake garantie(s) heeft verkregen.
                        </p><br /><br />
                        <p>
                            12.6  Voor behoud van garanties mogen onderhoud en reparaties uitsluitend in opdracht van of door E Boss worden verricht.
                        </p><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                    <Accordion.Header>13.	Melding</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            13.1	De Klant is verantwoordelijk voor het melden van een defect, een beroep op garantie(s) en/of indienen van een eventuele klacht over de uitvoering van de Overeenkomst of een Product.
                        </p> <br /><br />
                        <p>
                            13.2	Een melding op basis van lid 1 dient zo spoedig mogelijk, maar in ieder geval binnen 14 dagen na constatering, volledig en duidelijk omschreven te worden gemeld bij E Boss door een mail te sturen naar: info@E-Boss.nl of te bellen naar: 088-2029494
                        </p> <br /><br />
                        <p>
                            13.3	Indien tijdens het uitvoeren van de werkzaamheden door of namens  E Boss schade wordt toegebracht aan derden en/of zaken, anders dan de gebruikelijke installatie schades, dient de Klant E Boss direct - in ieder geval binnen 5 dagen na constatering van de schade - hierover te informeren.
                        </p><br /><br />
                        <p>
                            13.4	Indien een melding niet tijdig door de Klant is gedaan, verliest de Klant alle rechten ter zake.
                        </p><br /><br />
                        <p>
                            13.5	E Boss zal meldingen welke binnen de in lid 2 genoemde termijn worden ingediend behandelen en verhelpen indien deze gegrond zijn en de Klant een gegrond belang heeft.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                    <Accordion.Header>14.	Eigendomsvoorbehoud</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            14.1	Alle door E Boss geleverde Producten blijven eigendom van E Boss tot aan het moment van algehele voldoening van al hetgeen E Boss uit hoefde van de met de Klant gesloten Overeenkomst(en) te vorderen heeft.
                        </p> <br /><br />
                        <p>
                            14.2	De Klant dient tot de eigendomsoverdracht de geleverde Producten voor derden (waaronder potentiële kopers, deurwaarders, curatoren en dergelijke) duidelijk aan te merken als eigendom van E Boss.
                        </p> <br /><br />
                        <p>
                            14.3	De Klant is niet gerechtigd de Producten zonder schriftelijke toestemming van E Boss te verhuren, verpanden, verkopen of in gebruik te geven aan derden.
                        </p><br /><br />
                        <p>
                            14.4	E Boss heeft het recht de geleverde Producten direct terug te nemen, indien de Klant niet aan enige verplichting voldoet, dan wel E Boss aanleiding heeft gegeven aan te nemen dat de Klant niet aan enige verplichtingen zal voldoen.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                    <Accordion.Header>15.	Privacy</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            15.1	Partijen zich bij de uitvoering van de verplichtingen uit hoofde van de Overeenkomst aan alle geldende wet- en regelgeving ten aanzien van de bescherming van de persoonsgegevens, die betrekking heeft op de andere Partij.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                    <Accordion.Header>16.	Opschorting en ontbinding</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            16.1	E Boss is gerechtigd om per direct zonder ingebrekestelling en zonder rechterlijke tussenkomst hetzij de uitvoering van de Overeenkomst op te schorten, hetzij de Overeenkomst geheel of ten dele te ontbinden, zulks zonder dat zij tot enige schadevergoeding of garantie gehouden zal zijn en onverminderd de haar verder toekomende rechten, indien de Klant niet, niet behoorlijk of niet tijdig voldoet aan enige verplichting die voor haar uit de  met E Boss overeengekomen Overeenkomst of een daarmee samenhangende Overeenkomst voortvloeit.
                        </p> <br /><br />
                        <p>
                            16.2	In het vorige lid genoemde gevallen zijn alle vorderingen van E Boss op de Klant direct en in hun geheel opeisbaar en heeft E Boss het recht om de geleverde Producten terug te (doen) nemen.
                        </p> <br /><br />
                        <p>
                            16.3	Afwijkingen in de (specificaties van de) geleverde Producten ten opzichte van hetgeen in de Overeenkomst is opgenomen, leveren geen grond op voor schadevergoeding en/of ontbinding van de Overeenkomst.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                    <Accordion.Header>17.	Overmacht</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            17.1	E Boss is nimmer aansprakelijk wanneer ten gevolge van een overmacht situatie de verplichtingen op grond van de Overeenkomst niet kan worden nagekomen.
                        </p> <br /><br />
                        <p>
                            17.2	In geval van overmacht is E Boss gerechtigd hetzij de uitvoering van de Overeenkomst voor de duur van de overmacht op te schorten, hetzij de Overeenkomst geheel of gedeeltelijk te ontbinden, zulks zonder rechterlijke tussenkomst en zonder dat E Boss tot enige schadeloosstelling verplicht is. Gedurende de opschorting is E Boss bevoegd te kiezen voor uitvoering dan wel voor gehele of gedeeltelijke ontbinding van de Overeenkomst.
                        </p> <br /><br />
                        <p>
                            17.3	Onder overmacht wordt verstaan: elke van de wil van E Boss onafhankelijke omstandigheid – ook al was deze ten tijde van de totstandkoming van de Overeenkomst reeds te voorzien – die de nakoming van de Overeenkomst blijvend of tijdelijk verhindert of bezwaarlijk maakt.
                        </p><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                    <Accordion.Header>18.	Aansprakelijkheid</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            18.1	E Boss zal zich inspannen om de Overeenkomst naar behoren en met zorg uitte voeren, maar is uitsluitend aansprakelijk voor tekortkomingen indien de garantievoorwaarden van toepassing zijn.
                        </p> <br /><br />
                        <p>
                            18.2	E Boss aanvaardt geen aansprakelijkheid en kan door de Klant niet aansprakelijk worden gehouden voor enige (ingetrokken) subsidies, investeringen of anderszins gederfde inkomsten waarbij E Boss oorspronkelijk betrokken was bij het verkrijgen.
                        </p> <br /><br />
                        <p>
                            18.3	Onverminderd de elders in de Overeenkomst of deze Algemene voorwaarden overeengekomen beperkingen in de aansprakelijkheid van E Boss, is de aansprakelijkheid van E Boss beperkt tot het opnieuw uitvoeren van de betreffende werkzaamheden, dan wel tot vergoeding van uitsluitend de directe schade in verband met een toerekenbare tekortkoming in de nakoming van  de Overeenkomst. De hoogte van het door E Boss te betalen schadebedrag is beperkt tot maximaal 1⁄4 van het factuurbedrag van de Overeenkomst.
                        </p> <br /><br />
                        <p>
                            18.4	E Boss is nimmer aansprakelijk voor indirecte schade van de Klant of derden, waaronder mede begrepen gevolgschade, gederfde winst, gemiste besparingen, verminking dan wel verlies van (bedrijf)gegevens, zuivere vermogensschade en schade door bedrijfsstagnatie.
                        </p> <br /><br />
                        <p>
                            18.5	E Boss is niet aansprakelijk voor de gevolgen van wijzigingen in de belasting- en/ of energiewetgeving, indien de opbrengsten, dan wel de besparingen door   het Product lager wordt dan op het moment van de totstandkoming van de Overeenkomst.
                        </p><br /><br />
                        <p>
                            18.6	Voor zover de Klant schade heeft geleden welke is ontstaan door onrechtmatige daad, opzet en/of grove schuld van E Boss, dan wel van door E Boss ingeschakelde derden en/of ondergeschikten, is de hoogte van het door E Boss te betalen schadebedrag is beperkt tot een bedrag van € 5.000,- per schade toebrengend feit, waarbij een reeks van samenhangende feiten wordt gezien als één feit.
                        </p> <br /><br />
                        <p>
                            18.7	Ieder vorderingsrecht van de Klant, waaronder wegens schade of opnieuw uitvoering van de werkzaamheden, vervalt indien de tekortkoming en/
                            of de schade te laat wordt gemeld en vervalt in ieder geval één jaar na de schadeveroorzakende gebeurtenis.
                        </p><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                    <Accordion.Header>19.	Geheimhouding</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            19.1	De Klant zal -behoudens schriftelijke toestemming van E Boss- alles in het werk stellen om te voorkomen dat vertrouwelijke informatie m.b.t. de uitgevoerde Diensten ter kennis of in handen van derden komt.
                        </p> <br /><br />
                        <p>
                            19.2	In het bijzonder ziet de geheimhouding op de door E Boss werkwijze en/of verslaglegging aangaande de Overeenkomst met de Klant.
                        </p> <br /><br />
                        <p>
                            19.3	De geheimhoudingsverplichting legt de Klant ook de door haar in te schakelen derden op.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                    <Accordion.Header>20.	Intellectuele eigendomsrechten</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            20.1	E Boss behoudt zich alle rechten van intellectuele eigendom voor met betrekking tot producten van de geest welke zij gebruikt of heeft gebruikt en/of ontwikkelt en/of heeft ontwikkeld in het kader van de uitvoering van de Overeenkomst, en ten aanzien waarvan zij de auteursrechten of andere rechten van het intellectuele eigendom heeft, of geldend kan maken.
                        </p> <br /><br />
                        <p>
                            20.2	Alle (informatie die ligt besloten in) aanbiedingen, offertes, ontwerpen, afbeeldingen, tekeningen etc. en de daarmee verband houdende rechten van industriële en intellectuele eigendom c.q. daarmee gelijk te stellen rechten (waaronder auteursrechten, octrooirechten, etc.) en
                            knowhow worden en blijven eigendom van E Boss, ook al zijn de Klant voor vervaardiging daarvan kosten in rekening gebracht.
                        </p> <br /><br />
                        <p>
                            20.3	Het is de Klant niet toegestaan deze geheel of gedeeltelijk te kopiëren, aan derden ter hand te stellen of ter inzage te geven en/of van de inhoud
                            daarvan aan derden mededeling te doen, tenzij E Boss hiervoor voorafgaande schriftelijke toestemming heeft verleend.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                    <Accordion.Header>21.	Boete</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            21.1	Bij overtreding van het bepaalde in artikelen 15, 20 en 21 van deze Algemene voorwaarden verbeurt de Klant ten behoeve van E Boss zonder ingebrekestelling of gerechtelijke tussenkomst, een direct opeisbare en niet voor matiging vatbare boete van € 5.000,- en € 500,- voor elke dag of gedeelte daarvan dat de Klant in overtreding is
                        </p> <br /><br />
                        <p>
                            21.2	Een door de Klant verschuldigde boete laat onverlet het recht van E Boss op vergoeding van door haar geleden schade, kosten en rente.
                        </p> <br /><br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="21">
                    <Accordion.Header>22.	Overige bepalingen</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            22.1	E Boss mag de Algemene voorwaarden op ieder door haar gewenst moment (eenzijdig) wijzigen en/of aanvullen.
                        </p> <br /><br />
                        <p>
                            22.2	Indien enige bepaling of enig gedeelte van een bepaling van onderhavige Algemene voorwaarden om welke reden dan ook geheel of gedeeltelijk onverbindend is, doet zulks niet af aan de verbindendheid van de resterende bepalingen van deze Algemene voorwaarden c.q. van het resterende gedeelte van de betreffende bepaling.
                        </p> <br /><br />
                        <p>
                            22.3	In alle gevallen waarin de relatie tussen de Klant en E Boss eindigt, ingevolge enige bepaling van deze Algemene voorwaarden of door tussenkomst  van de rechter, blijven deze Algemene voorwaarden de rechtsverhouding tussen Partijen beheersen, voor zover dit voor de afwikkeling van de relatie noodzakelijk is.
                        </p><br /><br />
                        <p>
                            22.4	Op alle rechtsbetrekkingen tussen E Boss en de Klant is Nederlands recht van toepassing. Uitsluitend de rechtbank in Nederland is bevoegd om van geschillen kennis te nemen.
                        </p><br /><br />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default TermsAndConditions