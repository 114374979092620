import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomCarousel from "../carousel/Carousel";
import { Col, Row, Card, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { FaPinterestP } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import ReactCard from "../card/Card";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import featured from "./featured.png";

import featured2 from "./featured2.jpeg";
import featured3 from "./featured3.png";


// import moment from "moment";

import { useGetAllCategoriesQuery } from "../../features/productsApi";
// import axios from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { addCart, buyAgain, getCart } from "../../features/apiCall";
import { motion } from "framer-motion";
// import {
//   promotionFailure,
//   promotionStart,
//   promotionSuccess,
// } from "../features/promotionsSlice";

// import {
//   ordersFailure,
//   ordersStart,
//   ordersSuccess,
// } from "../features/ordersSlice";
import ReactImageZoom from "react-image-zoom";
import ReactImageMagnify from "react-image-magnify";
import AlertDanger from "../layout/AlertDanger";
import OurSatisfiedCustomers from "../layout/OurSatisfiedCustomers";
import LatestNews from "../layout/LatestNews";
// import { Translator, Translate } from "react-auto-translate";


const Home = () => {
  const [categories, setCategories] = useState([]);
  const { token } = useSelector((state) => state.auth);

  // const { promotions, loadingPromotion, promotionsErr } = useSelector(
  //   (state) => state.promotions
  // );

  // const { orders, loadingOrders, ordersErr } = useSelector(
  //   (state) => state.orders
  // );

  // const { buyAgainLoading, buyAgainErr } = useSelector(
  //   (state) => state.buyAgain
  // );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: categoryData, isLoading: categoryLoading } =
    useGetAllCategoriesQuery();

  // const fetchOrders = async () => {
  //   dispatch(ordersStart());

  //   try {
  //     const { data } = await axios.get("/api/order/recent-order", {
  //       headers: { Authorization: `${token}` },
  //     });

  //     // setOrders(data?.orders);
  //     dispatch(ordersSuccess(data?.orders));
  //   } catch (error) {
  //     console.log(error?.response?.data);
  //     dispatch(ordersFailure(error?.response?.data));
  //   }
  // };

  // const carouselDetails = async () => {
  //   dispatch(promotionStart());

  //   try {
  //     const { data } = await axios.get("/api/promotion/all", {
  //       headers: { Authorization: `${token}` },
  //     });

  //     // console.log("carousel ", data);
  //     // setPromotions(data?.promotions);
  //     dispatch(promotionSuccess(data?.promotions));
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(promotionFailure(error?.response?.data));
  //   }
  // };

  // const handleReOrder = async (orderId, product, quantity) => {
  //   await buyAgain(dispatch, { orderId });

  //   getCart(dispatch);

  //   navigate("/home/cart");
  // };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (categoryData) {
      setCategories(categoryData?.categories);
    }

    if (token) {
      getCart(dispatch);
    }

    // fetchOrders();

    // carouselDetails();
  }, [categoryData, token]);

  return (
    <>
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        {/* <ReactPlaceholder
          type="text"
          color="#F0F0F0"
          showLoadingAnimation
          rows={5}
          style={{ width: "60%", margin: "auto" }}
          ready={!loadingPromotion}
        > */}
        <CustomCarousel />
        {/* <CustomCarousel promotions={promotions} /> */}
        {/* </ReactPlaceholder> */}
        {/* <Translator
          // cacheProvider={cacheProvider}
          from="en"
          to="es"
          googleApiKey="AIzaSyDa1XtUxehWQu0ifml5AYWYs9a5iVp4AZA"
        > */}
        <div className="sec-1">
          <div className="sec-1-heading">
            <h1 className="h-heading">
              About us
            </h1>
          </div>

          <center >
            <p className="w-50">
              At Projectkart, we believe in creating a academic and IT projects for our valued customers. Whether you're looking for the web development, mobile apps development, or machine learning, deep learning, computer vision projects, we've got you covered.
              <strong> <Link to={'/home/about-us'}>Read more...</Link></strong>
            </p>
          </center>
        </div>
        <div className="sec-1">
          <div className="sec-1-heading">
            <h1 className="h-heading">
              {/* <Translate>Shop Categories</Translate> */}
              {/* <Translate>Shop Categories</Translate> */}
              Our Products
            </h1>
          </div>
          <div className="sec-1-body">
            <Row className="mr-3 gap-5 justify-content-center align-items-center">
              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={5}
                ready={!categoryLoading}
              >
                {categories?.length <= 0 ? (
                  <AlertDanger
                    heading={"Something went wrong!"}
                    desc={"We are working on resolving the issue."}
                    onHome={true}
                  />
                ) : (
                  categories?.map((item) => (
                    <Col
                      lg={3}
                      md={6}
                      className="shop-cate-home"
                      key={item._id}
                    >
                      <ReactCard item={item} />
                    </Col>
                  ))
                )}
              </ReactPlaceholder>
              {/* <ReactCard />
              <ReactCard />
              <ReactCard />
              <ReactCard />
              <ReactCard /> */}
            </Row>
          </div>
        </div>
        {/* </Translator> */}

        <OurSatisfiedCustomers />

        <LatestNews />
      </motion.div>
    </>
  );
};

export default Home;
