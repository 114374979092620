import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <>
            <Container
                style={{
                    marginTop: "2rem",
                }}
            >
                <h1>
                    PRIVACY POLICY
                </h1>
                Deze voorwaarden zijn van toepassing op alle diensten van E Boss (gevestigd de van Lanschotlaan 2, 5262 AG te Vught) aan gebruiker, evenals op alle informatie, aanbevelingen, stappenplannen, documenten en diensten die je op of via de website www.e-boss.nl verkrijgt en of gebruikt (verder te noemen: informatie). <br />
                <br />
                Heb je vragen over deze algemene voorwaarden of diensten van E Boss? Neem dan contact met ons op via info@e-boss.nl
                <br /><br />
                <ol>
                    <li>
                        <strong>
                            Algemeen
                        </strong>
                    </li>
                    <p>
                        1.1 E Boss neemt de grootst mogelijke zorgvuldigheid in acht bij de samenstelling en bij het onderhoud van de op de website www.e-boss.nl verstrekte informatie, echter kan E Boss niet garanderen dat deze informatie altijd volledig juist, compleet en actueel is. De informatie verstrekt op en via onze website is dan ook uitsluitend bedoeld als algemene informatie en hier kun je verder geen rechten aan ontlenen.
                    </p>
                    <br />
                    <p>
                        1.2 De inhoud van de website www.e-boss.nl kun je gebruiken om een beter beeld te krijgen van privacyregelgeving en kan helpen bij het vormgeven en tenuitvoerlegging van je privacy statement, maar kan niet worden opgevat als concreet juridisch advies. Het gebruik van de website www.e-boss.nl  is op eigen risico en voor eigen rekening. E Boss is niet aansprakelijkheid voor schade die direct of indirect voortvloeit uit het gebruik van (of het niet gebruiken van) deze website, inclusief schade veroorzaakt door bugs, scripts, virussen of eventuele onjuistheid of onvolledigheid van de informatie, tenzij de schade het gevolg is van opzet of grove nalatigheid van E Boss.
                    </p>
                    <br />
                    <p>
                        1.3 Iedere aansprakelijkheid van E Boss is beperkt tot het in totaal door E Boss aan een gebruiker in rekening gebrachte bedrag. Aanspraken vervallen indien E Boss niet binnen een jaar na de ontdekking van de gebeurtenis die aanleiding geeft tot aansprakelijkheid, hiervan schriftelijk in kennis is gesteld.
                    </p>
                    <br />
                    <p>
                        1.4 E Boss is niet aansprakelijk voor schade die voortvloeit uit het gebruik van elektronische communicatiemiddelen, met inbegrip van, zonder beperking, schade die voortvloeit uit het uitvallen of vertragen van de levering van elektronische communicatie, interceptie of manipulatie van elektronische communicatie door derden of door computerprogramma's gebruikt voor elektronische communicatie en transmissie van virussen.
                    </p>
                    <br />
                    <p>
                        1.5 E Boss is niet verantwoordelijk voor de wijze waarop gebruik wordt gemaakt van de ter beschikking gestelde functionaliteiten en informatie.
                    </p>
                    <br />
                    <p>
                        1.6 E Boss is niet aansprakelijk voor onjuiste feitelijke omstandigheden die door de gebruiker zijn verstrekt.
                    </p>
                    <br />
                    <p>
                        1.7 Als een deel van deze algemene voorwaarden nietig of vernietigbaar is, verandert dat niets aan de geldigheid van de rest van deze algemene voorwaarden of de overeenkomst waarop zij van toepassing zijn. Het nietige of vernietigde deel wordt vervangen door een bepaling die zoveel mogelijk de inhoud van de nietige bepaling volgt
                    </p>
                    <br />
                    <p>
                        Indien gewenst zal E Boss deze algemene voorwaarden op verzoek kosteloos aan je toezenden. Zij zijn ook beschikbaar op de website.
                    </p>
                    <br />
                    <li>
                        <strong>
                            Links naar en van andere websites
                        </strong>
                    </li>
                    <p>
                        2.1 Deze website biedt links naar externe websites en aanbieders. E Boss is niet aansprakelijk voor het gebruik of de inhoud van internetsites waarnaar wordt verwezen of aanbieders welke gekoppeld zijn aan de website van Webshop Keurmerk.
                    </p>
                    <br />
                    <p>
                        2.2 De site bevat links naar andere sites en sites van business partners die niet tot de E Boss behoren. Wij zijn niet aansprakelijk voor de privacy praktijken noch de inhoud van zulke sites.
                    </p>
                    <br />
                    <li>
                        <strong>
                            Auteursrechten en intellectuele eigendomsrechten
                        </strong>
                    </li>
                    <p>
                        3.1 Het auteursrecht van deze website en alle informatie binnen deze website berusten bij E Boss of bij derden welke met toestemming dit (beeld)materiaal beschikbaar hebben gesteld aan E Boss Het is dan ook niet toegestaan om de website (en onderdelen daarvan, en daarop) elektronisch of anderszins te kopiëren, openbaar te maken en/of wijzigingen in (onderdelen van) de website aan te brengen zonder voorafgaande toestemming van E Boss
                    </p>
                    <br />
                    <li>
                        <strong>
                            Toepasselijk recht & bevoegde rechter
                        </strong>
                    </li>
                    <p>
                        4.1 Deze algemene voorwaarden zijn uitsluitend onderworpen aan de Nederlandse wetgeving.
                    </p><br />
                    <p>
                        4.2 Bij mogelijke geschillen is de rechtbank te ’s Hertogenbosch exclusief bevoegd.
                    </p><br />
                    <p>
                        4.3 E Boss behoudt zicht het recht voor om deze gebruiksvoorwaarden van tijd tot tijd te wijzigen. Controleer deze daarom regelmatig.
                    </p><br />
                    <li>
                        <strong>
                            Privacy Statement
                        </strong>
                    </li>
                    <p>

                        E Boss hecht veel waarde aan de bescherming van uw persoonsgegevens. In dit Privacy Statement willen we heldere en transparante informatie geven over hoe wij omgaan met persoonsgegevens.
                    </p><br />
                    <p>
                        Wij doen er alles aan om je privacy te waarborgen en gaan daarom zorgvuldig om met persoonsgegevens. E Boss houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene Verordening Gegevensbescherming.  Dit brengt met zich mee dat wij in ieder geval:
                    </p><br />
                    <p>
                        - je persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt, deze doelen en type persoonsgegevens zijn beschreven in dit privacy statement.
                    </p><br />
                    <p>
                        - verwerking van je persoonsgegevens beperkt blijft tot enkel die gegevens welke minimaal nodig zijn voor de doeleinden waarvoor ze worden verwerkt.
                    </p><br />
                    <p>
                        - vragen om je uitdrukkelijke toestemming als wij deze nodig hebben voor de verwerking van jouw persoonsgegevens.
                    </p><br />
                    <p>
                        - passende technische en/of organisatorische maatregelen hebben genomen zodat de beveiliging van
                    </p>
                    <br />
                    <strong>
                        jouw persoonsgegevens gewaarborgd is.
                    </strong>
                    <br />
                    <p>

                        - geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor de uitvoering van de doeleinden waarvoor ze zijn verstrekt.
                    </p><br />
                    <p>
                        - op de hoogte zijn van jouw rechten omtrent je persoonsgegevens, je hier op willen wijzen en deze
                        respecteren.
                    </p>
                    <br />
                    <p>
                        E Boss is verantwoordelijk voor de verwerking van jouw persoonsgegevens. Indien je na het doornemen van ons privacy statement, of in algemenere zin, vragen hebt hierover of contact met ons wenst op te nemen kan dit via onderstaande contactgegevens::
                    </p><br />
                    E Boss <br />
                    Postbus 21 <br />
                    5575 ZG Luijksgestel <br />
                    Info@e-boss.nl <br />

                    <strong>
                        Waarvoor verwerken wij persoonsgegevens?
                    </strong>
                    <p>
                        Je persoonsgegevens worden door E Boss verwerkt ten behoeve van de volgende doeleinden:
                    </p>
                    <br />
                    <ul>
                        <li>
                            - Het versturen van nieuwsbrieven en uitnodigingen.
                        </li>
                        <li>
                            - Het opmaken van de factuur
                        </li>
                        - Uw persoonsgegevens en webshop gegevens worden vermeld op onze website www.e-boss.nl. Op deze manier kunnen consumenten checken of u bij ons bent aangesloten. Let goed op! In uw account kunt u onder het kopje 'Webshops' achter de url kiezen voor 'wijzigen' aldaar kunt u de openbare gegevens te allen tijde aanpassen.
                        <br />
                    </ul>

                    <br />
                    Voor de bovenstaande doelstellingen kunnen wij de volgende persoonsgegevens van je vragen:
                    <br />
                    <ul>
                        <li>
                            - Voorletters en achternaam;
                        </li>
                        <li>
                            - Tussenvoegsel;
                        </li>
                        <li>
                            - E-mailadres;
                        </li>
                        <li>
                            - Geslacht;
                        </li>
                        <li>
                            - Telefoonnummer;
                        </li>
                        <li>
                            - Rekeninggegevens;
                        </li>
                        <li>
                            - Bedrijfsgegevens (KvKnummer, Vestigingsnummer, Btwnummer en Adresgegevens).
                        </li>
                    </ul>
                    <br />

                    Verstrekking aan derden <br />

                    De gegevens die je aan ons geeft kunnen wij aan derde partijen verstrekken indien dit noodzakelijk is voor de uitvoering van de hierboven beschreven doeleinden.
                    <ul>
                        <br />
                        Zo maken wij gebruik van een derde partij voor:
                        <li> - Het verzorgen van de internetomgeving voor E Boss</li>
                        <li>   - Het verzorgen van de (financiële) administratie;</li>
                        <li>  - Het verzorgen van nieuwsbrieven en uitnodigingen.</li>
                    </ul>
                    <br />

                    Wij geven nooit persoonsgegevens door aan andere partijen waarmee we geen verwerkersovereenkomst hebben afgesloten. Met deze partijen (verwerkers) maken wij hierin uiteraard de nodige afspraken om de beveiliging van je persoonsgegevens te waarborgen.
                    <br />

                    Verder zullen wij de door jou verstrekte gegevens niet aan andere partijen verstrekken, tenzij dit wettelijk verplicht en toegestaan is. Een voorbeeld hiervan is dat de politie in het kader van een onderzoek (persoons)gegevens bij ons opvraagt. In een dergelijk geval dienen wij medewerking te verlenen en zijn dan ook verplicht deze gegevens af te geven. Tevens kunnen wij persoonsgegevens delen met derden indien jij ons hier schriftelijk toestemming voor geeft.
                    <br />
                    Wij verstrekken geen persoonsgegevens aan partijen die gevestigd zijn buiten de EU.
                    <br />
                    <br /><br />
                    <strong>
                        Minderjarigen
                    </strong><br />
                    Wij verwerken enkel en alleen persoonsgegevens van minderjarigen (personen jonger dan 16 jaar) indien daarvoor schriftelijke toestemming is gegeven door de ouder, verzorger of wettelijke vertegenwoordiger.
                    Bewaartermijn
                    E Boss bewaart persoonsgegevens niet langer dan noodzakelijk voor het doel waarvoor deze zijn verstrekt dan wel op grond van de wet is vereist.
                    <br /><br />
                    <strong>
                        Beveiliging
                    </strong><br />
                    Wij hebben passende technische en organisatorische maatregelen genomen om persoonsgegevens van jou beschermen tegen onrechtmatige verwerking. Zo hebben we bijvoorbeeld de volgende maatregelen genomen:
                    - Alle personen die namens E Boss van je gegevens kennis kunnen nemen, zijn gehouden aan geheimhouding daarvan.
                    - We hanteren een gebruikersnaam en wachtwoordbeleid op al onze systemen;
                    - We pseudonimiseren en zorgen voor de encryptie van persoonsgegevens als daar aanleiding toe is;
                    - Wij maken back-ups van de persoonsgegevens om deze te kunnen herstellen bij fysieke of technische incidenten;
                    - We testen en evalueren regelmatig onze maatregelen;
                    - Onze medewerkers zijn geïnformeerd over het belang van de bescherming van persoonsgegevens.
                    <br /><br />
                    <strong>
                        Rechten omtrent je gegevens
                    </strong><br />
                    Je hebt recht op inzage, rectificatie of verwijdering van de persoonsgegeven die we van je hebben. Tevens kan je bezwaar maken tegen de verwerking van jouw persoonsgegevens (of een deel hiervan) door ons of door een van onze verwerkers. Ook heb je het recht de door jou verstrekte gegevens door ons te laten overdragen aan jouzelf of direct aan een andere partij indien gewenst. Wij kunnen je vragen om je te legitimeren voordat wij gehoor kunnen geven aan voornoemde verzoeken.
                    <br /><br />
                    <strong>
                        Klachten
                    </strong><br />
                    Mocht je een klacht hebben over de verwerking van je persoonsgegevens, dan vragen we je hierover met ons contact op te nemen.  Je hebt altijd het recht een klacht in te dienen bij de <code>  Autoriteit Persoonsgegevens, dit is de toezichthoudende autoriteit op het gebied van privacy.
                    </code>
                    <br /><br />
                    Als je naar aanleiding van ons privacy statement nog vragen of opmerkingen hebt, neem dan contact met ons op via info@e-boss.nl

                </ol>

            </Container>
            <Container
                style={{
                    marginTop: "2rem",
                }}
            >
                <h1>
                    Disclaimer
                </h1>
                <strong> <br />
                    Laatst bijgewerkt: 08 september 2023
                </strong> <br /><br />

                <strong>

                    Interpretatie en definities
                </strong>
                <br />
                <strong>
                    Interpretatie
                </strong>
                <br />
                De woorden waarvan de beginletter met een hoofdletter is geschreven, hebben de betekenis die hieronder is gedefinieerd. De volgende definities hebben dezelfde betekenis ongeacht of ze in het enkelvoud of meervoud voorkomen.
                <br /><br />
                <strong>
                    Definities
                </strong><br />
                In deze Disclaimer wordt verstaan onder: <br /><br />
                <ul>
                    <li>Bedrijf (in deze Disclaimer aangeduid als "het Bedrijf", "Wij", "Ons" of "Onze") verwijst Keystone Financial Services B.V., Van Lanschotlaan 2, 5262AG Vught.</li>
                    <li>Dienst verwijst naar de Website.</li>
                    <li>U verwijst naar de persoon die toegang heeft tot de Dienst, of het bedrijf, of andere juridische entiteit namens welke deze persoon toegang heeft tot de Dienst of deze gebruikt, zoals van toepassing.</li>
                    <li>Website verwijst naar E Boss, toegankelijk via http://www.e-boss.nl</li>
                </ul>

                <strong>
                    Disclaimer
                </strong><br />
                De informatie op de Service is uitsluitend bedoeld voor algemene informatiedoeleinden.
                <br /><br />
                Het Bedrijf aanvaardt geen verantwoordelijkheid voor fouten of weglatingen in de inhoud van de Service.
                <br /><br />
                In geen geval zal het Bedrijf aansprakelijk zijn voor enige speciale, directe, indirecte, gevolg- of incidentele schade of welke schade dan ook, hetzij in een contractuele actie, nalatigheid of andere onrechtmatige daad, voortvloeiend uit of in verband met het gebruik van de Service of de inhoud van de Service. Het Bedrijf behoudt zich het recht voor om op elk gewenst moment zonder voorafgaande kennisgeving toevoegingen, verwijderingen of wijzigingen in de inhoud van de Service aan te brengen. Deze Disclaimer is opgesteld met behulp van de Free Disclaimer Generator.
                <br /><br />
                Het Bedrijf garandeert niet dat de Service vrij is van virussen of andere schadelijke componenten.
                <br /><br />
                <strong>
                    Disclaimer externe links
                </strong>
                <br />
                De Service kan links bevatten naar externe websites die niet worden aangeboden of onderhouden door of op enigerlei wijze gelieerd zijn aan de Onderneming.
                <br /><br />
                Houd er rekening mee dat het Bedrijf de juistheid, relevantie, tijdigheid of volledigheid van de informatie op deze externe websites niet garandeert.
                <br /><br />
                <strong>
                    Disclaimer voor fouten en omissies
                </strong>
                <br /><br />
                De informatie die door de Service wordt gegeven, is uitsluitend bedoeld als algemene leidraad voor interessante zaken. Ook al neemt het Bedrijf alle voorzorgsmaatregelen om ervoor te zorgen dat de inhoud van de Service zowel actueel als accuraat is, kunnen er fouten optreden. Bovendien kunnen er, gezien de veranderende aard van wetten, regels en voorschriften, vertragingen, weglatingen of onnauwkeurigheden voorkomen in de informatie op de Service.
                <br /><br />
                Het bedrijf is niet verantwoordelijk voor fouten of omissies, of voor de resultaten verkregen door het gebruik van deze informatie.
                <br /><br />
                <strong>
                    Disclaimer fair gebruik
                </strong> <br />
                De Onderneming kan auteursrechtelijk beschermd materiaal gebruiken dat niet altijd specifiek is geautoriseerd door de eigenaar van het auteursrecht. Het bedrijf stelt dergelijk materiaal beschikbaar voor kritiek, commentaar, nieuwsverslaggeving, onderwijs, wetenschap of onderzoek.
                <br /><br />
                Het bedrijf is van mening dat dit een "eerlijk gebruik" is van dergelijk auteursrechtelijk beschermd materiaal.
                <br /><br />
                Als U auteursrechtelijk beschermd materiaal van de Service wilt gebruiken voor uw eigen doeleinden die verder gaan dan redelijk gebruik, moet U toestemming verkrijgen van de eigenaar van het auteursrecht.
                <br /><br />
                <strong>
                    Disclaimer geuite meningen
                </strong><br />
                De Service kan standpunten en meningen bevatten die van de auteurs zijn en niet noodzakelijkerwijs het officiële beleid of standpunt van een andere auteur, instantie, organisatie, werkgever of bedrijf weerspiegelen, inclusief het Bedrijf.
                <br /><br />
                Reacties gepubliceerd door gebruikers zijn uitsluitend hun verantwoordelijkheid en de gebruikers zullen de volledige verantwoordelijkheid, aansprakelijkheid en schuld op zich nemen voor eventuele smaad of rechtszaken die voortvloeien uit iets geschreven in of als een direct gevolg van iets geschreven in een reactie. Het bedrijf is niet aansprakelijk voor commentaar gepubliceerd door gebruikers en behoudt zich het recht voor om commentaar te verwijderen om welke reden dan ook.
                <br /><br />
                <strong>
                    Afwijzing van aansprakelijkheid
                </strong><br />
                De informatie op de Service wordt verstrekt met dien verstande dat het Bedrijf hierin niet betrokken is bij het geven van juridisch, boekhoudkundig, fiscaal of ander professioneel advies en diensten. Als zodanig dient het niet te worden gebruikt ter vervanging van overleg met professionele boekhoudkundige, fiscale, juridische of andere deskundige adviseurs.
                <br /><br />
                In geen geval zal het Bedrijf of haar leveranciers aansprakelijk zijn voor enige speciale, incidentele, indirecte of gevolgschade die voortvloeit uit of verband houdt met uw toegang tot of gebruik van de Service of de onmogelijkheid om toegang te krijgen tot of gebruik te maken van de Service.
                <br /><br />
                <strong>
                    "Disclaimer "Gebruik op eigen risico
                </strong>
                Alle informatie in de Service wordt aangeboden "zoals ze is", zonder garantie op volledigheid, nauwkeurigheid, tijdigheid of op de resultaten verkregen door het gebruik van deze informatie, en zonder garantie van welke aard dan ook, expliciet of impliciet, met inbegrip van, maar niet beperkt tot garanties van prestatie, verhandelbaarheid en geschiktheid voor een bepaald doel.
                <br /><br />
                Het Bedrijf is niet aansprakelijk jegens U of iemand anders voor een beslissing genomen of actie ondernomen op basis van de informatie gegeven door de Service of voor gevolgschade, speciale schade of soortgelijke schade, zelfs indien geadviseerd over de mogelijkheid van dergelijke schade.
                <br /><br />
                <strong>
                    Contact met ons opnemen
                </strong><br />
                Als U vragen heeft over deze Disclaimer, kunt U contact met ons opnemen:
                <br /><br />
                Per e-mail: info@e-boss.nl
            </Container>
        </>
    )
}

export default PrivacyPolicy