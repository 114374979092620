import { useNavigate } from "react-router-dom";

function ReactBreadcrumb({ path, other }) {
  const navigate = useNavigate();

  const navigation = (p) => {
    if (p === "Home") {
      navigate("/");
    } else if (p === "My-Account") {
      navigate("/home/my-account");
    }
  };

  return (
    <div className="breadcrum">
      <div key={path} className="d-inline-block">
        {path.split(" ").map((p, i) => (
          <span
            style={{ cursor: "pointer" }}
            key={Math.random()}
            onClick={() => navigation(p)}
          >
            <span className="breadcrum-word">{`${p}`}</span>
          </span>
        ))}
      </div>
    </div>
  );
}

export default ReactBreadcrumb;
