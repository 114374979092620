import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Badge,
} from "react-bootstrap";
import {
  AiOutlineHome,
  AiOutlineShop,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { MdAutorenew, MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import { RiChatQuoteLine } from "react-icons/ri";
import { HiOutlineUser } from "react-icons/hi";
import { TbMailForward } from "react-icons/tb";
import DropdownComp from "./DropdownComp";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../features/authSlice";
import { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { setCart } from "../../features/cartSlice";
import { setBuyAgain } from "../../features/buyAgain";
import { setOrders } from "../../features/ordersSlice";
import { setOrder } from "../../features/orderSlice";
import { BsGlobe2 } from "react-icons/bs";

import app_logo_black from "./logo.jpg";
// import app_logo_white from "./dark_logo_white_background.jpg";
import app_logo_white from "./logo.jpg";
import LanguageDropdown from "./LanguageDropdown";
// import app_logo_black from "./white_logo_dark_background.jpg";

function ReactHeader() {
  const { token } = useSelector((state) => state.auth);
  const { cartItems, isFetching } = useSelector((state) => state.cart);
  const [width, setWidth] = useState(window.innerWidth);
  const [scroll, setScroll] = useState(false);
  const [scrollDir, setScrollDir] = useState("scrolling down");
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const path = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await dispatch(logOut());
    await dispatch(setCart());
    await dispatch(setBuyAgain());
    // await dispatch(setReviews());
    await dispatch(setOrders());
    await dispatch(setOrder());
    // navigate("/");
  };

  // 767
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? setScroll(true) : setScroll(false));

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  useEffect(() => {
    if (path.pathname.startsWith("/shop") && expand) {
      setExpand(!expand);
    }
  }, [path]);

  const breakpoint = 767;

  return (
    <>
      {width > breakpoint ? (
        <Navbar
          className={`${
            path.pathname === "/" ? "h-dark" : "h-light header-nav"
          }`}
          expand="md"
          sticky="top"
          collapseOnSelect
        >
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link role="button" className="nav-link" tabIndex="0" to="/">
                  Home
                </Link>
                <NavDropdown title="Services" id="basic-nav-dropdown">
                  <DropdownComp />
                </NavDropdown>
                {/* <Link role="button" className="nav-link" tabIndex="0" to="/">
                  Renewable
                </Link> */}
                <Link
                  role="button"
                  className="nav-link"
                  tabIndex="0"
                  to="/home/about-us"
                >
                  About Us
                </Link>
                <Link
                  role="button"
                  className="nav-link"
                  tabIndex="0"
                  to="/home/contact-us"
                >
                  Contact Us
                </Link>
              </Nav>
              <Navbar.Brand
                className={`${
                  cartItems?.length !== 0 && token && "center-app-logo"
                } ${token && cartItems?.length === 0 && "center-logo"} ${
                  !token && "app-logo"
                }`}
              >
                <Link to="/">
                  <img
                    className="brand-img"
                    alt="projectkart"
                    src={
                      path.pathname === "/" ? app_logo_white : app_logo_black
                    }
                    width="80"
                  />
                </Link>
              </Navbar.Brand>
              {/* <InputGroup className="header-form" size="sm">
                <MdSearch className="header-from-icon" />
                <Form.Control
                  style={{
                    borderRadius: "2rem",
                  }}
                  // placeholder="Username"
                  // aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup> */}
              {/* <Form className="">
                <div className="header-search-box">
              <MdSearch />
                  <input type="text" placeholder="" classname="header-form" />
                </div>
              </Form> */}

              <Nav className="ms-auto">
                {/* <LanguageDropdown /> */}
                {/* <NavDropdown
                  title={<BsGlobe2 />}
                  id="basic-nav-dropdown"
                  className="align-items-center"
                >
                </NavDropdown> */}

                
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <>
          <Navbar
            className={`${
              path.pathname === "/" ? "h-dark-mobile" : "h-light header-nav"
            }`}
            collapseOnSelect
            expand="md"
            sticky="top"
            expanded={expand}
            variant={path.pathname === "/" ? "dark" : "light"}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "0rem 0.7rem",
              }}
            >
              <Container fluid>
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  onClick={() => setExpand(!expand)}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                >
                  <Offcanvas.Header
                    closeButton
                    onClick={() => setExpand(!expand)}
                  ></Offcanvas.Header>

                  <Offcanvas.Body
                    id="responsive-navbar-nav"
                    style={{ padding: "1rem" }}
                  >
                    <Nav className="me-auto">
                      <Link
                        role="button"
                        className="nav-link"
                        tabIndex="0"
                        to="/"
                        onClick={() => setExpand(!expand)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "0.5px solid #E6E6E680",
                            paddingBottom: "0.4rem",
                          }}
                        >
                          <AiOutlineHome style={{ marginRight: "0.5rem" }} />
                          <p>Home</p>
                        </div>
                      </Link>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "0.5px solid #E6E6E680",
                        }}
                      >
                        <AiOutlineShop
                          style={{
                            position: "fixed",
                            top: "117px",
                          }}
                        />
                        <NavDropdown
                          style={{ marginLeft: "1.5rem" }}
                          title="Shop"
                          id="collasible-nav-dropdown"
                        >
                          <DropdownComp width={width} />
                        </NavDropdown>
                      </div>

                      {/* <Link
                        role="button"
                        className="nav-link"
                        tabIndex="0"
                        to="/"
                        onClick={() => setExpand(!expand)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "0.5px solid #E6E6E680",
                            paddingBottom: "0.4rem",
                          }}
                        >
                          <MdAutorenew style={{ marginRight: "0.5rem" }} />
                          <p>Renewable</p>
                        </div>
                      </Link> */}

                     
                        
                      <Link
                        role="button"
                        className="nav-link"
                        tabIndex="0"
                        to="/home/about-us"
                        onClick={() => setExpand(!expand)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "0.5px solid #E6E6E680",
                            paddingBottom: "0.4rem",
                          }}
                        >
                          <FaUsers style={{ marginRight: "0.5rem" }} />
                          <p>About Us</p>
                        </div>
                      </Link>

                      <Link
                        role="button"
                        className="nav-link"
                        tabIndex="0"
                        to="/home/contact-us"
                        onClick={() => setExpand(!expand)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "0.5px solid #E6E6E680",
                            paddingBottom: "0.4rem",
                          }}
                        >
                          <TbMailForward style={{ marginRight: "0.5rem" }} />
                          <p>Contact Us</p>
                        </div>
                      </Link>
                      {/* <input type="text" name="search" className="" /> */}
                    </Nav>
                    {/* <Form className="d-flex"></Form> */}

                    <Nav className="ms-auto">
                      

                      

                   

                   
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
              <Link
                to="/"
                style={{
                  position: "fixed",
                  right: "0",
                  top: "0",
                  marginTop: "0.8rem",
                  marginRight: "1.4rem",
                }}
              >
                <img
                  alt="projectkart"
                  src={path.pathname === "/" ? app_logo_white : app_logo_black}
                  width="75"
                />
              </Link>
            </div>
          </Navbar>

          {!scroll && (
            <div
              className={`${
                path.pathname === "/" ? "mobile-nav-dark" : "mobile-nav-light"
              } mobile-nav`}
            >
              <div className="mobile-nav-icons">
                <div>
                  <Link
                    role="button"
                    className="nav-link"
                    tabIndex="0"
                    to={"/"}
                  >
                    <AiOutlineHome className="mobile-icons" />
                  </Link>
                </div>
                <div>
             
                </div>
                <div>
                  
                </div>
                <div>
                 
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ReactHeader;
