import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ProtectedRoute from "./utils/ProtectedRoute";
// import { getCart } from "./features/apiCall";
// import { useDispatch } from "react-redux";

import Footer from "./components/layout/Footer"
import Header from "./components/layout/Header";
import Cart from "./components/pages/Cart";
import Checkout from "./components/pages/Checkout";
import Order from "./components/pages/Order";
import Home from "./components/pages/Home";
import SignIn from "./components/pages/SignIn";
import SignUp from "./components/pages/SignUp";
import NotFound from "./components/pages/NotFound";
import GetQuote from "./components/pages/GetQuote";
import Profile from "./components/pages/Profile";
import Addresses from "./components/pages/Addresses";
import AllOrders from "./components/pages/AllOrders";
import AddReview from "./components/pages/AddReview";
import ProductList from "./components/pages/ProductList"
import ProductDetails from "./components/pages/ProductDetails";
import AboutUs from "./components/pages/AboutUs";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import ContactUs from "./components/pages/ContactUs";

function App() {
  // const dispatch = useDispatch();
  const pageLocation = useLocation();
  // const cls = window.location.pathname === '/' ? "h-dark" : "h-light";
  const cls = "h-light";

  // useEffect(() => {
  //   getCart(dispatch);
  // }, []);

  return (
    <>
      {/* {location.country !== "CA" ? (
        <NotAllowed />
      ) : ( */}
      <>
        <Header />
        <AnimatePresence mode={"wait"}>
          <Routes location={pageLocation} key={pageLocation.pathname}>
            <Route
              path="/"
              element={
                <>
                  <Home />
                </>
              }
            />
            <Route
              path="/shop/:id"
              element={
                <>
                  <ProductList />
                </>
              }
            />

            <Route
              path="/home/:id"
              element={
                <>
                  <ProductDetails />
                </>
              }
            />
            
            <Route
              path="/home/about-us"
              element={
                <>
                  <AboutUs />
                </>
              }
            />
            <Route
              path="/home/terms_and_conditions"
              element={
                <>
                  <TermsAndConditions />
                </>
              }
            />
            <Route
              path="/home/privacy_and_policy"
              element={
                <>
                  <PrivacyPolicy />
                </>
              }
            />
            <Route
              path="/home/contact-us"
              element={
                <>
                  <ContactUs />
                </>
              }
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/not-found" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
        <Footer />
      </>
      {/* )} */}
    </>
  );
}

export default App;
