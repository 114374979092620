import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import cartReducer from "./cartSlice";
import reviewReducer from "./reviewSlice";
import relatedPrdsReducer from "./relatedProdSlice";
import orderReducer from "./orderSlice";
import buyAgainReducer from "./buyAgain";
import ordersReducer from "./ordersSlice";
import getProductReducer from "./getProdSlice";
import promotionsReducer from "./promotionsSlice";
import { productsApi } from "./productsApi";

export default configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    review: reviewReducer,
    relatedPrds: relatedPrdsReducer,
    order: orderReducer,
    orders: ordersReducer,
    buyAgain: buyAgainReducer,
    promotions: promotionsReducer,
    product: getProductReducer,
    [productsApi.reducerPath]: productsApi.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(productsApi.middleware);
  },
});
