/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer } from "react";
import { Col, Container, Row, Table, Accordion } from "react-bootstrap";
// import { Link } from "react-router-dom";

import edwin_aboutUs from "./edwin_aboutUs.png";
import about_img from "./about_img.png";
import aboutus_map from "./aboutus_map.png";
import axios from "../../utils/axios";
import { useSelector } from "react-redux"

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        companyDetails: action.payload,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


const AboutUs = () => {
  const [{ loading, error, companyDetails }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const { data } = await axios.get(`/api/static/get-static`, {
          headers: { Authorization: token },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (error) {
        dispatch({
          type: "FETCH_FAIL",
          payload: error,
        });
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="aboutus-top-img">
        <img src={edwin_aboutUs} alt="" />
      </div>

      <Container
        style={{
          marginTop: "2rem",
        }}
      >
        <Row className="about_edwin">
          <Col md={7}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p className="about-edwin-heading">About Projectkart</p>

              <div className="about-edwin-info">
                {/* {companyDetails?.map((detail) => ( */}
                <div>
                  <p>{companyDetails?.staticContent?.aboutUs?.slice(0, 19)}</p>

                  <p>{companyDetails?.staticContent?.aboutUs?.slice(19)}</p>
                </div>
                {/* ))} */}
              </div>
            </div>
          </Col>
          <Col>
            <div className="about-img">
              <img src={about_img} alt="" />
            </div>
          </Col>
        </Row>

        <div className="locations-cont">
          <p className="about-edwin-heading">Our locations</p>

          <p className="locations-info">
            We have our offices at these locations.{" "}
          </p>

          <Row className="location-cont">
            <Col md={4}>
              {companyDetails?.staticContent?.contactUs?.address?.map(
                (detail) => (
                  <div key={detail} className="location-div">
                    <p
                      style={{
                        fontWeight: "600",
                      }}
                      className="locations-details"
                    >
                      {detail}
                    </p>

                    {/* <div>
                      <Link
                        style={{
                          fontWeight: "600",
                        }}
                        className="view-map"
                      >
                        View Map
                      </Link>
                    </div> */}
                  </div>
                )
              )}
            </Col>
            <Col>
              <div
                className="aboutus-map-img"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={aboutus_map} alt="" />
              </div>
            </Col>
          </Row>
         
        </div>
      </Container>
    </>
  );
};

export default AboutUs;
