import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";

const ProductList = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Col md={5} lg={3} sm={6} key={item?._id}>
      <div
        className="products-container"
        onClick={() => navigate(`/home/${item?._id}`)}
      >
        <div>
          <Card
            style={{
              width: "15rem",
              height: "23rem",
            }}
            className="prod-card"
          >
            {item?.sale && (
              <div className="top-right">
                <span>-10</span>
                <span>New</span>
              </div>
            )}
            {item?.product_images?.length > 0 ? (
              <Card.Img
                variant="top"
                src={item?.product_images[0]}
                style={{
                  width: "auto",
                  height: "15rem",
                }}
              />
            ) : (
              <Card.Text style={{ marginBottom: "70%", padding: "1rem" }}>
                No image
              </Card.Text>
            )}
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  {item?.name}
                </p>
                <p
                  style={{
                    fontSize: "0.8rem",
                    marginTop: "0.5rem",
                    color: "#194719",
                  }}
                >
                  {item?.description?.slice(0, 25)}...
                </p>
              </div>

              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Rs{item?.amount?.toFixed(2)}
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Col>
  );
};

export default ProductList;
