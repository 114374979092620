import React, { useState } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useReducer } from "react";
import axios from "../../utils/axios";

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_REQUEST":
      return { ...state, loading: true };
    case "ADD_SUCCESS":
      return {
        ...state,
        msg: action.payload,
        loading: false,
        error: "",
      };
    case "ADD_FAIL":
      return { ...state, loading: false, error: action.payload, msg: "" };
    default:
      return state;
  }
};

const ContactUs = () => {
  const [{ loading, error, msg }, dispatch] = useReducer(reducer, {
    loading: false,
    error: "",
  });
  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile_no: "",
    topic: "",
    message: "",
  });

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };


  // const [role, setRole] = useState("");

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, firstname, lastname, message, mobile_no, topic } = values;

    if (topic === "") {
      toast.error("Please select a topic!", toastOptions);
      return;
    }

    dispatch({ type: "ADD_REQUEST" });
    try {
      const { data } = await axios.post("/api/contactus/add-contactus", {
        email,
        firstname,
        lastname,
        message,
        mobile_no,
        topic,
      });

      dispatch({ type: "ADD_SUCCESS", payload: data?.msg });
    } catch (error) {
      dispatch({
        type: "ADD_FAIL",
        payload: error?.response?.data?.error?.message,
      });
    }

    // setValues({
    //   firstname: "",
    //   lastname: "",
    //   email: "",
    //   mobile_no: "",
    //   topic: "",
    //   message: "",
    // });
  };

  useEffect(() => {
    if (!error && msg) {
      toast.success("Details submitted!", toastOptions);

      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [error, msg]);

  return (
    <>
      <Container>
        <div className="contactus-cont">
          <div
            className="contactus-upper"
            style={
              {
                // textAlign: "center",
              }
            }
          >
            <p className="contactus-upper-title">Contact us</p>
          </div>

          <div className="contactus-form">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputPassword5">First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      placeholder="First Name"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputPassword5">Last name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputPassword5">Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputPassword5">
                      Phone number
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="mobile_no"
                      placeholder="Phone number"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputPassword5">Choose a topic</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="topic"
                  onChange={handleChange}
                >
                  <option value="">Select one...</option>
                  <option value="Buy projects and services">
                      Buy Academic Projects and Services
                  </option>
                  <option value="Buy projects and services">
                      Want to make custom Websites or Webapps
                  </option>
                  <option value="Buy projects and services">
                      Want to make custom Android or Ios app
                  </option>
                  <option value="Get quote">Get quote for your IT requirement</option>
                  <option value="Know more about our projects and services">
                    Know more about our products and services
                  </option>
                  <option value="Consult us about your custom needs">
                    Consult us about your custom requirements
                  </option>
                </Form.Select>
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="inputPassword5">
                  Which best describes you?
                </Form.Label>
                <Row>
                  <Col md={6}>
                    <Form.Check
                      onChange={(e) => setRole(e.target.value)}
                      name="First choice"
                      type={"radio"}
                      value={"First choice"}
                      label={`First choice`}
                      checked={role === "First choice"}
                    />
                    <Form.Check
                      onChange={(e) => setRole(e.target.value)}
                      name="Second choice"
                      value={"Second choice"}
                      type={"radio"}
                      label={`Second choice`}
                      checked={role === "Second choice"}
                    />
                    <Form.Check
                      onChange={(e) => setRole(e.target.value)}
                      name="Third choice"
                      value={"Third choice"}
                      type={"radio"}
                      label={`Third choice`}
                      checked={role === "Third choice"}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Check
                      onChange={(e) => setRole(e.target.value)}
                      value={"Fourth choice"}
                      name="Fourth choice"
                      type={"radio"}
                      label={`Fourth choice`}
                      checked={role === "Fourth choice"}
                    />
                    <Form.Check
                      onChange={(e) => setRole(e.target.value)}
                      value={"Fifth choice"}
                      name="Fifth choice"
                      type={"radio"}
                      label={`Fifth choice`}
                      checked={role === "Fifth choice"}
                    />
                    <Form.Check
                      onChange={(e) => setRole(e.target.value)}
                      value={"Other"}
                      name="Other"
                      type={"radio"}
                      label={`Other`}
                      checked={role === "Other"}
                    />
                  </Col>
                </Row>
              </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputPassword5">Message</Form.Label>
                <Form.Control
                  as={"textarea"}
                  name="message"
                  placeholder="Type your message..."
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              

              {loading ? (
                <Button variant="dark" size="lg" disabled>
                  <Spinner animation="border" variant="light" />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="dark"
                  disabled={ msg}
                >
                  Submit
                </Button>
              )}
            </Form>
          </div>
        </div>
      </Container>

      <ToastContainer />
    </>
  );
};

export default ContactUs;
